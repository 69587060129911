import React, { useState } from "react";
import Swal from "sweetalert2";
import useCeo from "../../hooks/useCEO";
import { useCarga } from "../../hooks/useCarga";
import { Loader } from "../../components/Loader";

export const CargaArchivos = () => {
  const [loading, setLoading] = useState(false);
  useCeo({
    title: "Carga de archivos",
    description: "Carga de archivo",
  });

  const [imgCollection, setImgCollection] = useState([]);
  const [listaUpload, setListaUpload] = useState([]);
  const { postCarga } = useCarga();

  const onFileChange = (e) => {
    setImgCollection(e.target.files);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    var formData = new FormData();

    for (const key of Object.keys(imgCollection)) {
      formData.append("imgCollection", imgCollection[key]);
    }

    // var formData = new FormData();

    for (const key of Object.keys(imgCollection)) {
      formData.append("imgCollection", imgCollection[key]);
    }

    const resp = await postCarga(formData);
    if (!resp.ok) {
      setLoading(false);
      // setImgCollection([]);
      return Swal.fire({
        title: "Lo siento!",
        text: resp.msg,
        icon: "info",
      });
    } else {
      const obj = [];
      // setImgCollection([]);
      for (var i = 0; i < imgCollection.length; i++) {
        obj.push(imgCollection[i].name);
      }
      setListaUpload(obj);
      setLoading(false);
      return Swal.fire({
        title: "¡Completado!",
        text: resp.msg,
        icon: "success",
      });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="card w-75 container p-4">
        <h5 className="card-title">Subir archivos</h5>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <input
              type="file"
              name="imgCollection"
              className="form-control"
              onChange={onFileChange}
              multiple
              required
            />
          </div>
          <br />

          <button className="btn btn-outline-success float-end" type="submit">
            Cargar archivo(s)
          </button>
        </form>

        <ul>
          {listaUpload &&
            listaUpload.map((img, index) => <li key={index}>{img}</li>)}
        </ul>
      </div>
    </>
  );
};
