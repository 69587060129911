import { axiosHelper } from "../helpers/axiosHelper";

export const useUnidades = () => { 
  const getUsuarios = async () => {
    let resp = await axiosHelper({
      url: "users",
    });
    return resp;
  };

  const putUsuario = async (usuario) => {
    let resp = await axiosHelper({
      url: "users/" + usuario.id,
      method: "put",
      data: usuario,
    });
    debugger;
    return resp;
  };

  const getUsuario = async (id) => {
    let resp = await axiosHelper({
      url: "users/" + id,
    });
    return resp;
  };

  const postUsuario = async (usuario) => {
    let resp = await axiosHelper({
      url: "users",
      method: "post",
      data: usuario,
    });
    return resp;
  };

   const deleteUser = async (data) => {
    let resp = await axiosHelper({
      url: "users/" + data.id,
      data: data,
      method: "delete",
    });
    return resp;
  };
  const getRoles = async () => {
    let resp = await axiosHelper({
      url: "roles",
    });
    return resp;
  };
  
  return {
   putUsuario,
   getUsuarios,
   postUsuario,
   getRoles,
   getUsuario,
  //  getNegocios,
  //  getCedisNegociosAll,
   deleteUser,
  };
};
