import React, { useState, useEffect, useContext } from "react";
import MUIDataTable from "mui-datatables";
import Swal from "sweetalert2";
import { useUnidades } from "../../hooks/useUnidades";
import useCeo from "../../hooks/useCEO";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";
import { Loader } from "../../components/Loader";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faUser,
  faUserPlus,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/authContext/authContext";

const initialStateFormUsuarios = {
  name: "",
  email: "",
  password: "",
  id: "",
  users_num_empleado: "",
  roles_id: 0,
};

export const Usuarios = () => {
  const columns = [
    "Nombre",
    "Correo",
    "Empleado",
    "Rol",
    "Estatus",
    "Acciones",
  ];

  const options = {
    selectableRows: "none",
    downloadOptions: {
      filename: "usuarios.csv",
      // separator: ";",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      CharacterData: "utf-8",
      CharSet: "utf-8",
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    textLabels: {
      body: {
        noMatch: "No se encontraron registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Página siguiente",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtro de tabla",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "LIMPIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas de tabla",
      },
      selectedRows: {
        text: "Fila(s) seleccionada(s)",
        delete: "Remover",
        deleteAria: "Fila seleccionada removida",
      },
    },
  };

  useCeo({
    title: "ADMINISTRACIÓN DE USUARIOS",
    description: "Página de usuarios admon",
  });
  const {
    getUsuarios,
    putUsuario,
    postUsuario,
    getRoles,
    getUsuario,
    getNegocios,
    getCedisNegociosAll,
    deleteUser,
    // getHorarios,
  } = useUnidades();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialStateFormUsuarios);
  const { user } = useContext(AuthContext);
  // const [usuarios, setUsuarios] = useState([]);
  const [roles, setRoles] = useState([]);
  // const [horarios, setHorarios] = useState([]);
  const [negocio, setNegocio] = useState([]);
  const [cedis, setCedis] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleShow = () => setShow(true);
  useEffect(() => {
    loadInfo();
    users();
  }, []);
  const loadInfo = async () => {
    setLoading(true);
    let resp = await getRoles();
    debugger
    if (resp.ok) setRoles(resp.roles);
    // resp = await getHorarios();
    // if (resp.ok) setHorarios(resp.horarios);
    // obtenerUsuarios();
    setLoading(false);
  };
  // const obtenerUsuarios = async () => {
  //   let users = await getUsuarios();
  //   if (users.ok) setUsuarios(users.users);
  // };
  const usarUsuario = async (id) => {
    setLoading(true);
    let resp = await getUsuario(id);
    debugger
    if (resp.ok)
      setForm({
        ...form,
        password: "",
        ...resp.usuario,
      });
    setLoading(false);
  };
  const save = async (event) => {
    event.preventDefault();
    if (!form.id) {
      const flag = validaciones();
      if (!flag) return "";
    }
    setLoading(true);
      let resp;
      if (form.id){
        resp = await putUsuario({
          password: form.password,
          email: form.email,
          users_num_empleado: form.users_num_empleado ? form.users_num_empleado : "",
          roles_id: form.roles_id,
        });
      } else {
        resp = await postUsuario({
          email: form.email,
          name: form.name,
          password: form.password,
          id: form.id,
          users_num_empleado: form.users_num_empleado ? form.users_num_empleado : "",
          roles_id: form.roles_id,
        });
      }
    
    handleClose();
    setLoading(false);

    if (!resp.ok)
      return Swal.fire({
        title: "Lo siento!",
        text: resp.msg,
        icon: "info",
      });
    Swal.fire({
      title: "Operación realizada correctamente",
      text: resp.msg,
      icon: "success",
    });
    reset();
    users();
    // obtenerUsuarios();
  };
  const reset = () => {
    setForm(initialStateFormUsuarios);
    setForm([]);
  };
  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const validaciones = () => {
    if (!form.name) showToastError("Favor de insertar nombre de usuario");

    if (!form.email) showToastError("Favor de insertar email");

    if (!form.password && !form.id)
      showToastError("Favor de insertar la contraseña");

    if (form.password && !form.id && form.password.length < 8) {
      showToastError("La contraseña debe tener al menos 8 caracteres");
      return false;
    }

    if (!form.roles_id) showToastError("Favor de seleccioanr rol");

    if (form.roles_id === "4" && !form.cedis_id)
      showToastError("Favor de elegir cedis/hub del admin");

    if (form.roles_id === "4" && !form.telefono)
      showToastError("Es requerido el campo teléfono");

    if(form.telefono && form.telefono.length !== 10) showToastError("El número de telefono deve ser 10 digitos");

    // if (form.roles_id === "2" && !form.cat_tipo_horario_id)
    //   showToastError("Favor de elegir horario del monitorista");

    if (
      !form.roles_id ||
      !form.password ||
      (!form.id && !form.name) ||
      !form.email 
      // (form.roles_id === "2" && !form.cat_tipo_horario_id)
    )
      return false;

    return true;
  };
  const showToastError = (msj = "Ha ocurrido un error") => {
    toast.error(msj, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const statusUsuario = async (id_usuario, activo) => {
    let resp = await deleteUser({
      id: id_usuario,
      isActive: activo,
    });
    if (resp.ok) {
      users();
      // await obtenerUsuarios();
      return Swal.fire({
        title: "¡Completado!",
        text: resp.msg,
        icon: "success",
      });
    } else {
      return Swal.fire({
        title: "No se pudo actualizar el usuario",
        text: resp.msg,
        icon: "error",
      });
    }
  };
  // console.log("form", form);
  const users = async () => {
    setLoading(true);
    let resp = await getUsuarios();
    setLoading(false);
    if (resp.ok) {
      let array = [];
      resp.users.map((registro) => {
        const {
          id,
          name,
          email,
          users_num_empleado,
          role,
          activo,
        } = registro;

        let aux = [
          name ? name : "N/A",
          email ? email : "N/A",
          users_num_empleado ? users_num_empleado : "N/A",
          role ? role.title : "N/A",
          activo ? "Activo" : "Inactivo",
          <>
            <FontAwesomeIcon
              icon={faEdit}
              className="fa-2x text-secondary cursor"
              onClick={() => {
                handleShow();
                usarUsuario(id);
              }}
              title="Editar usuario"
            />
            {activo ? (
              <FontAwesomeIcon
                icon={faUser}
                className="fa-2x text-success cursor"
                title={"Dar de baja usuario"}
                onClick={() => {
                  Swal.fire({
                    title: "¿Deseas deshabilitar la cuenta de usuario?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "¡Si!",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      return statusUsuario(id, 0);
                    }
                  });
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faUser}
                className="fa-2x text-danger"
                title={"Habilitar usuario"}
                onClick={() => {
                  Swal.fire({
                    title: "¿Deseas habilitar la cuenta de usuario?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "¡Si!",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      return statusUsuario(id, 1);
                    }
                  });
                }}
              />
            )}
          </>,
        ];
        array.push(aux);
        return "";
      });
      setData([...array]);
    }
    setLoading(false);
  };
  
  return (
    <>
      <div className="d-flex justify-content-center p-2">
        <FontAwesomeIcon
          icon={faUserPlus}
          className="fa-2x text-dark pb-2 mr-2 cursor"
          onClick={handleShow}
          title="Agregar usuario"
        />
      </div>
      {loading && <Loader />}
      <MUIDataTable
        title={"Usuarios"}
        data={data}
        columns={columns}
        options={options}
        onDownload={(buildHead, buildBody, columns, data) => {
          return "\uFEFF" + buildHead(columns) + buildBody(data);
        }}
      />
        <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <form className="form-card" onSubmit={save}>
          <Modal.Header closeButton>
            <Modal.Title>
              {!form.id ? "Crear usuario" : "Actualizar usuario"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-between text-left">
              <div className="form-group col-sm-6 flex-column d-flex">
                {" "}
                <Input
                  name="name"
                  id="name"
                  onChange={handleChange}
                  value={form.name}
                  type="text"
                  // required
                  labelText={"Nombre del usuario"}
                  placeholder={"Escribe el nombre del usuario"}
                  classNames="mb-4"
                  disabled={form.id ? true : false}
                ></Input>{" "}
              </div>
              <div className="form-group col-sm-6 flex-column d-flex">
                {" "}
                <Input
                  name="users_num_empleado"
                  id="users_num_empleado"
                  onChange={handleChange}
                  value={form.users_num_empleado}
                  type="number"
                  labelText={"Número de empleado"}
                  placeholder={"Escribe el número de empleado"}
                  classNames="mb-4"
                ></Input>{" "}
              </div>
            </div>
            <div className="row justify-content-between text-left">
              <div className="form-group col-sm-6 flex-column d-flex">
                {" "}
                <Select
                  name="roles_id"
                  value={form.roles_id}
                  className="form-control mb-4"
                  onChange={handleChange}
                  labelText="Rol del usuario"
                  placeholder="SELECCIONA"
                  // required
                  options={roles.map((x) => {
                    return {
                      value: x.id,
                      text: x.title,
                    };
                  })}
                ></Select>{" "}
              </div>
            </div>
            <div className="row justify-content-between text-left">
              <div className="form-group col-sm-6 flex-column d-flex">
                {" "}
                <Input
                  name="password"
                  value={form.password}
                  id="password"
                  onChange={handleChange}
                  type="password"
                  labelText={"Contraseña"}
                  placeholder={
                    !form.id
                      ? "Escribe tu contraseña"
                      : "Escribe la contraseña si desea cambiarla"
                  }
                  classNames="mb-4"
                  // required={!form.id}
                ></Input>{" "}
              </div>
              <div className="form-group col-sm-6 flex-column d-flex">
                {" "}
                <Input
                  name="email"
                  id="email"
                  onChange={handleChange}
                  value={form.email}
                  type="email"
                  // required
                  labelText={"Correo electronico"}
                  placeholder={"Escribe el correo del usuario"}
                  classNames="mb-4"
                ></Input>{" "}
              </div>
            </div>
            <br></br>
            <div className="row justify-content-center p-1">
              <div className="form-group col-sm-6"> </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              id="liberarSalida"
              type="submit"
              className="btn btn-lg liberarSalida"
              style={{ background: "#ffcd00" }}
            >
              <FontAwesomeIcon icon={faSave} />
              &nbsp; {form.id ? "Actualizar" : "Guardar"}
            </button>
          </Modal.Footer>
        </form>
        {/* {!form.id && (
          <Modal.Footer>
            <button
              id="liberarSalida"
              className="btn btn-lg liberarSalida"
              style={{ background: "#B0C4DE" }}
              onClick={reset}
            >
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp; Reset
            </button>
          </Modal.Footer>
        )} */}
      </Modal>
      
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};
